.massive-appraisal-summary-view {
  --side-padding: 48px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 64px;
    padding: 0 var(--side-padding);

    background-color: var(--primary-background);

    .btn-go-back {
      position: absolute;
      left: 8px;
    }

    h1 {
      margin-bottom: 0;
      font-size: 18px;
    }

    span {
      font-size: 14px;
    }

    .btn-download {
      background: var(--primary-slim);
      border-radius: 12px;
      font-size: 18px;
      display: flex;
      align-items: center;
    }
  }

  .sub-header {
    display: none;
  }

  .appraisals-table {
    padding: 0 var(--side-padding);
  }

  @media (max-width: 1023px) {
    --side-padding: 32px;

    .header {
      .btn-go-back {
        left: 2px;
      }

      h1 {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }

    .appraisals-table {
      padding: 0;
    }
  }

  @media (max-width: 767px) {
    .sub-header {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-info {
        display: flex;
        align-items: center;
        height: 32px;

        padding: 0 4px;
        font-size: 12px;
        border: 1px solid #8186f7;
        border-radius: 8px;
        padding: auto;
      }

      .btn-download {
        border-radius: 8px;
        font-size: 12px;
      }
    }

    .appraisals-table {
      thead {
        display: none;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;