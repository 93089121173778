.massive-appraisalContainer {
  color: var(--dark-zero);
  margin-top: 20px;
  padding-bottom: 10px;
  .massive-appraisalTitle {
    background-color: var(--primary-background);
    padding: 6px 10px 6px 100px;
    width: 350px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      margin: 0px;
    }
  }
  .massive-appraisal {
    margin: auto;
    width: 70vw;
    margin-top: 10px;
    text-align: center;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
    }
    .massiveValorizationDragger {
      width: 60%;
      min-width: 500px;
      margin: auto;
      img {
        padding-bottom: 25px;
      }
      .ant-upload {
        border-radius: 0px;
        padding: 25px 0px;
      }
    }
    .ant-btn-primary:disabled {
      background-color: var(--light-four);
      border-color: var(--dark-two);
      color: var(--dark-two);
      img {
        content: url("../../assets/iconExcelBlack.svg");
      }
    }
    .ant-btn-primary {
      height: 50px;
      width: 30%;
      min-width: 300px;
      background-color: var(--primary-slim);
      border-color: var(--primary-slim);
      margin: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      img {
        content: url("../../assets/iconXLS.svg");
        padding-right: 10px;
        width: 25px;
      }
    }
    .ant-upload-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: var(--dark-one);
    }
    .ant-upload-hint {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: var(--dark-one);
    }
  }
  .massive-appraisalHint {
    text-align: center;
    width: 80%;
    margin: auto;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: var(--primary-darker);
    }
  }
  .massive-appraisalFooter {
    display: flex;
    width: 90%;
    margin: 15px auto;
    justify-content: space-between;

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17p;
      margin: 10px;
      display: inline-block;
      width: 85%;
    }
    .massive-appraisalSteps {
      max-width: 40%;

      .massive-appraisalStep {
        display: flex;
        align-items: center;
      }
      .massive-appraisalExampleButton {
        display: flex;
        justify-content: center;
      }
      .ant-btn-primary {
        height: 50px;
        width: 60%;
        min-width: 300px;
        background-color: var(--primary-slim);
        border-color: var(--primary-slim);
        margin: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
    }
    .massive-appraisalExample {
      max-width: 30%;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      img {
        width: 100%;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;