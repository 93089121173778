.property-summary-container {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .property-images,
  .property-data {
    overflow-y: scroll;
  }

  .btn-back {
    padding: 0;
    left: -4px;
  }

  .btn-edit {
    position: absolute;
    width: 185px;
    height: 31px;
    left: 235px;
    top: 82px;
    border-radius: 12px;
    padding: 0;
    background: #643eff;

    h1 {
      //styleName: Short Medium / 15pt;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: white;
    }
  }

  svg {
    height: 16px;
    width: 16px;
  }

  .property-data {
    flex: 1;
    --side-padding: 32px;

    padding: 16px var(--side-padding) 32px;

    h2 {
      position: relative;
      left: calc(-1 * var(--side-padding));
      width: fit-content;
      padding: 8px var(--side-padding);
      margin: 16px 0;

      color: var(--dark-zero);
      font-size: 20px;
      background-color: var(--primary-background);
    }

    .btn-edit-prop {
      width: 185px;
      height: 31px;
      left: 0px;
      top: 0px;

      margin: 24px 0;
      padding: 10px;

      /* Primary / Lighter */
      background: #643eff;

      /* Light / Elevation 02 */
      box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
      border-radius: 12px;

      /* Short Medium / 15pt */
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;

      /* identical to box height */
      display: flex;
      align-items: center;

      /* Light / Light 4 */
      color: #ffffff;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .btn-save-prop {
      width: 170px;
      height: 31px;
      left: 0px;
      top: 0px;

      margin: 24px 0;
      padding: 10px;

      /* Primary / Lighter */
      background: #643eff;

      /* Light / Elevation 02 */
      box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
      border-radius: 12px;

      /* Short Medium / 15pt */
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;

      /* identical to box height */
      display: flex;
      align-items: center;

      /* Light / Light 4 */
      color: #ffffff;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .property-subheader-container {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      height: 62px;

      h3 {
        max-width: 65%;
      }

      .property-published-price-number {
        display: flex;
        align-items: center;
        width: 200px;

        .anticon {
          font-size: 20px;
        }

        h4 {
          font-size: 20px;
          color: var(--primary-main);
          margin: 0 4px;
          line-height: normal;
        }
      }
      span {
        display: block;
        font-size: 14px;
      }

      .price-input {
        height: 48px;
      }
    }

    .property-edit-container {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;

      h3 {
        max-width: 65%;
      }

      .property-published-price-number {
        display: flex;
        align-items: center;
        width: 200px;

        .anticon {
          font-size: 20px;
        }

        h1 {
          //styleName: Short Medium / 15pt;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 22px;
          background: #ffffff;
        }

        h4 {
          font-size: 20px;
          color: var(--primary-main);
          margin: 0 4px;
          line-height: normal;
        }
      }
      span {
        display: block;
        font-size: 14px;
      }

      .price-input {
        height: 48px;
      }
    }

    .map-container {
      height: 300px;
      position: relative;
      width: calc(100% + 2 * var(--side-padding));
      left: calc(-1 * var(--side-padding));

      .mapboxgl-map {
        border-radius: 0px;
      }
    }

    .summary-data-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px var(--side-padding);
      border-bottom: 1px solid var(--primary-subtle);

      position: relative;
      width: calc(100% + 2 * var(--side-padding));
      left: calc(-1 * var(--side-padding));

      span {
        color: var(--dark-two);
        font-size: 18px;
        display: flex;
        align-items: center;

        .anticon {
          font-size: 24px;
          margin-right: 8px;
        }
      }
    }

    .summary-table {
      border: 1px solid var(--primary-lavander);
      border-radius: 12px;
      margin: 16px 0;
      padding: 24px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;

      .summary-table-item {
        font-size: 16px;
        line-height: normal;
        h4 {
          display: inline;
          margin-right: 8px;
          font-weight: normal;
        }
        span {
          color: var(--dark-three);
        }
        .selection-popover {
          justify-content: left;
        }
      }
    }

    .summary-card-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      row-gap: 32px;
    }

    .property-value-conclusion {
      grid-column: 1 / span 2;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      margin: 16px;

      em {
        font-style: normal;
        &.good-value {
          color: var(--green-zero);
        }
        &.bad-value {
          color: var(--red-zero);
        }
      }
    }

    .tassapp-explain-text {
      border-radius: 12px;
      border: 1px solid var(--primary-lavander);
      padding: 8px;
      font-size: 13px;
      text-align: justify;
      margin-bottom: 0;
    }
    .footer {
      min-height: 40px;
    }
  }

  .property-images {
    width: 200px;
  }
}

@media (max-width: 1199px) {
  .property-summary-container {
    height: 100%;
    .property-data {
      --side-padding: 48px;
    }
  }
}

@media (max-width: 1023px) {
  .property-summary-container {
    .property-data {
      --side-padding: 16px;

      .btn-edit-prop {
        width: 185px;
        height: 31px;

        font-size: 15px;
      }

      .summary-table {
        padding: 12px;
        .summary-table-item {
          font-size: 14px;
        }
      }
    }
    .property-images {
      width: 120px;
    }
  }
}

@media (max-width: 767px) {
  .property-summary-container {
    flex-wrap: wrap;

    .property-data {
      --side-padding: 8px;
      padding: 8px var(--side-padding);

      h2 {
        font-size: 14px;
        margin: 8px 0;
      }

      .property-subheader-container {
        margin: 8px 0;

        h3 {
          font-size: 11px;
        }

        .property-published-price-number {
          .anticon {
            font-size: 16px;
          }

          h4 {
            font-size: 14px;
          }
        }

        span {
          font-size: 9px;
        }
      }

      .property-images {
        position: relative;
        display: flex;
        margin-bottom: 8px;
        width: calc(100% + 2 * var(--side-padding));
        left: calc(-1 * var(--side-padding));

        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        .ant-image,
        img {
          height: 96px;
          width: auto;
          margin: 0 1px;
        }
      }

      .map-container {
        height: 200px;
      }

      .summary-data-icons {
        padding: 8px 16px;

        span {
          font-size: 12px;

          .anticon {
            font-size: 26px;
            margin-right: 4px;
          }
        }
      }

      .summary-table {
        padding: 8px;
        grid-template-columns: 1fr 240px 1fr;
        gap: 8px;

        .summary-table-item {
          grid-column: 2;
          font-size: 12px;
        }
      }

      .summary-card-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;
        row-gap: 16px;
      }

      .property-value-conclusion {
        font-size: 16px;
        margin: 8px;
      }

      .tassapp-explain-text {
        border-radius: 12px;
        padding: 4px;
        font-size: 9px;
      }
      .footer {
        min-height: 40px;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;