.edit-property-form {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
    font-weight: 600;
  }
  p {
    color: var(--primary-lighter);
    text-align: center;
    width: 360px;
    margin: 0 auto;
  }

  .ant-form {
    flex: 1;
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    align-content: space-between;

    .ant-form-item {
      margin-bottom: 8px;
      margin-top: 10px;
    }

    .ant-form-item-explain {
      display: none;
    }

    .col-2 {
      grid-column-end: span 2;
    }

    .inline {
      justify-content: center;
      flex-direction: row;
      align-items: center;
      .ant-form-item-label {
        margin-right: 8px;
        padding: 0;
        flex: unset;
      }
      .ant-form-item-control {
        flex: 0;
      }
    }

    label {
      font-size: 14px;
      font-weight: normal;
      color: var(--dark-one);
    }

    .ant-switch {
      background-color: var(--primary-lavander);
      &.ant-switch-checked {
        background-color: var(--primary-main);
      }
    }

    button.ant-btn-primary {
      grid-column-end: span 2;
      justify-self: center;
      padding: 0 24px;

      &:not(:disabled) {
        background-color: var(--primary-main);
        border-color: var(--primary-main);
      }
    }
  }
}

@media (max-width: 1199px) {
}

@media (max-width: 767px) {
}

@media (max-width: 480px) {
  .ant-form-item {
    margin-top: 13px;
    margin-bottom: 12px;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;