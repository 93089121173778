.appraisal-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  --side-padding: 32px;
  padding: var(--side-padding);

  h1.appraisal-header {
    position: relative;
    left: calc(-1 * var(--side-padding));
    padding: 8px var(--side-padding);
    display: inline-block;

    font-size: 20px;
    color: var(--dark-zero);
    background-color: var(--primary-background);
    margin-right: auto;
  }

  .appraisal-summary {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .appraisal-summary-content {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 640px;
    column-gap: 48px;
    row-gap: 32px;

    .appraisal-summary-property {
      display: flex;
      flex-direction: column;

      .appraisal-summary-address {
        font-size: 17px;
        color: var(--dark-zero);
      }

      .map-container {
        flex: 1;
      }
      .summary-grid {
        height: 280px;
        padding: 0 16px;
        margin-top: 16px;
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 16px;
        align-content: space-evenly;

        border: 1px solid #c9caeb;
        border-radius: 12px;
        font-size: 16px;
        color: var(--dark-three);
        transition: all 1s ease;

        strong {
          font-weight: normal;
          color: var(--dark-one);
        }
      }
      .btn-see-more {
        display: none;
      }
    }
    .appraisal-summary-result {
      display: grid;
      grid-template-columns: repeat(2, 320px);
      column-gap: 16px;
      row-gap: 32px;
      align-content: flex-start;
      justify-content: space-evenly;

      h2 {
        font-size: 20px;
        color: var(--primary-darker);
        grid-column-end: span 2;
        text-align: center;
      }

      button {
        margin: auto;
        background: var(--primary-lighter);
        border-color: var(--primary-lighter);
      }
    }
  }
}

.blocked {
  align-self: center;
  display: flex;
  font-size: 14px;
  color: #4d29dd;
  font-weight: 400;
  cursor: pointer;
  .icon {
    position: relative;
    margin-left: 0px;
  }
}

.similar-properties-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-left: 0px;
  justify-content: space-between;
  padding-right: 0px;
  margin-bottom: 10px;

  .properties-counter {
    height: 100%;
    align-self: center;
    border: 1px solid #4124b5;
    border-radius: 12px;
    justify-content: space-between;
    padding: 5px 8px;
  }
  .radio-switch {
    height: 100%;
    margin-top: 2px;
  }
  .sort-filter {
    height: 100px;
  }
}

.map-header {
  margin-bottom: 10px;
  width: 100%;
}

.map-container-2 {
  height: 600px;
  width: 100%;
  overflow: hidden;
  .mapboxgl-map {
    border-radius: 12px;
  }
}

.similar-properties-description {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 30px;
}

.similar-properties-display {
  overflow-y: scroll;
  height: 600px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  .searchResultsGrid {
    padding-right: 6px;
  }
}

@media (min-width: 1310px) {
  .similar-properties-display {
    .searchResultsGrid {
      padding-right: 20px;
    }
  }
}

@media (max-width: 1199px) {
  .appraisal-view {
    height: auto;
    .appraisal-summary-content {
      display: flex;
      flex-direction: column;

      .appraisal-summary-property {
        flex: 1;

        .map-container {
          min-height: 240px;
          display: grid;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .appraisal-view {
    .appraisal-summary-content {
      row-gap: 16px;
      .appraisal-summary-property .summary-grid {
        height: 160px;
      }
    }
  }
  .similar-properties-header {
    font-size: 14px;
    padding-right: 20px;
  }
  .map-header {
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .appraisal-view {
    --side-padding: 16px;
    min-height: 100%;
    height: min-content;
    padding: 8px var(--side-padding);

    h1.appraisal-header {
      font-size: 16px;
      padding: 4px var(--side-padding);
    }

    .appraisal-summary-content {
      row-gap: 8px;
      .appraisal-summary-property {
        .appraisal-summary-address {
          font-size: 12px;
          margin-bottom: 4px;
        }

        .map-container {
          position: relative;
          min-height: 120px;
          width: calc(100% + 2 * var(--side-padding));
          left: calc(-1 * var(--side-padding));
          .mapboxgl-map {
            border-radius: 0;
          }
        }
        .summary-grid {
          grid-template-columns: auto;
          height: 216px;
          margin-top: 8px;
          gap: 0;
          justify-content: center;

          border-radius: 8px;
          font-size: 12px;

          &.summarized {
            height: 104px;
            .summarized-hide {
              display: none;
            }
          }
        }
        .btn-see-more {
          margin: 4px auto 0;
          padding: 0 12px;
          font-size: 10px;
          display: block;
          border: none;
          box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
        }
      }
      .appraisal-summary-result {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        h2 {
          font-size: 14px;
        }

        button {
          border-radius: 8px;
        }
      }
    }
  }
  .blocked {
    font-size: 10px;
  }
  .similar-properties-header {
    padding-right: 0px;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;