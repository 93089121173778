.searchView {
  display: grid;
  flex-direction: column;
  grid-template-columns: 720px 1fr;
  grid-template-rows: repeat(3, auto) 1fr;
  align-content: start;
  height: 100%;
  padding: 16px;
  gap: 8px 16px;
  overflow: hidden;

  h2 {
    font-size: 10px;
  }
}

.filtersOptions {
  grid-column-end: span 2;
  display: flex;
  justify-content: space-between;

  .filterPopover {
    flex: content;
    margin: 0 4px;
    button {
      width: 100%;
    }
  }
  .filterToggle {
    flex: content;
    margin: 0 4px;
    max-width: max-content;
    min-width: max-content;
  }
}

.filterSelected {
  grid-column-end: span 2;
  margin: 0 56px;
  display: flex;
  flex-wrap: wrap;
}

.search-filters-drawer-content {
  h4 {
    color: var(--dark-zero);
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .selectFilterItem {
    padding: 8px 24px;

    border-bottom: none;
    text-align: left;
  }

  .ant-slider {
    margin: 16px 8px 48px;
  }

  .search-filters-drawer-buttons {
    margin: 32px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 160px;
      height: 36px;
      margin-top: 8px;

      &.ant-btn-primary {
        background-color: var(--primary-slim);
        border-color: var(--primary-slim);
      }
    }
  }
}

.resultsScrollable,
.mapContainer {
  align-self: stretch;

  .map {
    height: 100%;
  }
}

.results-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    position: relative;
    left: -24px;
    display: flex;
    align-items: center;
    padding: 6px 32px 6px;
    margin: 0;

    background-color: var(--primary-background);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    vertical-align: middle;
  }

  .results-header-options {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    & > * {
      margin: 0 4px;
    }
  }
}

//MOBILE ACTTIONS
.filterPopover {
  display: flex;
  justify-content: center;
}

.ant-popover-inner {
  overflow: hidden;
  .ant-popover-inner-content {
    padding: 0px;
  }
}

.MobileActionsInput,
.MobileActionsContent {
  min-width: 200px;

  .MobileActionsItem,
  .MobileActionsItem {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 12px;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-background);
    }
  }

  .MobileActionsCategory,
  .MobileActionsItem {
    border-bottom: 1px solid #e1e1f2;
  }

  .active {
    color: var(--primary-main);
  }
}

.map-header {
  display: flex;
  box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;

  .actions {
    display: flex;
    justify-content: space-evenly;
    .ant-btn {
      border: none;
      box-shadow: none;

      svg path {
        fill: var(--dark-one);
      }
    }
  }
}

.resultsScrollable {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  .searchResultsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;

    height: auto;
    width: 100%;
    padding-right: 16px;

    &.horizontal {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }

    .no-results {
      grid-column-end: span 3;
      justify-self: center;
    }
  }

  .seeMoreBtnContainer {
    display: flex;
    justify-content: center;
    margin: 20px;

    .seeMoreBtn {
      font-style: normal;
      font-size: 15;
      font-weight: 500;
      border: 0;
      box-shadow: 4px 4px 20px 0px rgba(129, 134, 246, 0.2),
        5px 5px 10px 0px rgba(129, 134, 246, 0.1);
      color: var(--dark-three);

      height: 40px;
      width: 120px;
    }
  }
}

.row-display {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0px 0px;
  overflow: auto;
  position: relative;

  .display-element {
    margin: 25px;
    width: 150px;
    height: 200px;
    margin-bottom: 0px;

    .display-element-img {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 12px;
      :hover {
        cursor: pointer;
      }
    }
    .text {
      margin-top: 15px;
      align-content: center;
      display: flex;
      font-size: 12px;
      justify-content: space-evenly;
      font-weight: 400;
    }
  }
}

@media (max-width: 1199px) {
  .searchView {
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr 1fr;

    .map-header {
      display: none;
    }

    .searchResultsGrid {
      padding: 0;
    }
  }
}

@media (max-width: 767px) {
  .searchView {
    padding: 8px 8px 16px;
  }

  .results-header {
    flex-direction: column;
    align-items: flex-start;

    h2 {
      margin-bottom: 8px;
      font-size: 16px;
    }

    .results-header-options {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .viewToggle {
        display: none;
      }

      .ant-btn,
      .filterPopover {
        margin: 0;
      }
    }
  }

  button.ant-btn.mapOrGridButton {
    background-color: var(--primary-slim);
    border-color: var(--primary-slim);
    margin: 8px auto 0;
    display: flex;
    align-items: center;
    padding: 0 16px;

    svg {
      height: 16px;
      path {
        fill: white;
      }
      margin-right: 8px;
    }
  }
}

@media (max-width: 480px) {
  .searchView {
    grid-template-rows: auto 1fr;
  }

  .hidden {
    display: none !important;
  }

  .resultsScrollable .searchResultsGrid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 8px;
  }
}

.property-summary-modal .ant-modal-body {
  padding: 0px;
  overflow: hidden;
  height: 90vh;
}

.collection-modal {
  margin-bottom: 0px;
}

.collection-modal .ant-modal-header {
  padding: 7px 2px;
  .ant-modal-title {
    display: flex;
    align-items: center;
    .text {
      padding: 7px 2px;
      margin: auto;
      font-size: 20px;
      font-weight: 500;
    }
    .plus-button {
      margin-right: 5px;
      width: 32px;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.collection-modal .ant-modal-body {
  padding: 0px;
  padding-right: 15px;
  .new-collection-body {
    padding-top: 30px;
    display: flex;
    overflow: hidden;
    align-content: center;

    .display-element {
      margin: auto;
      width: 155px;
      height: 200px;
      overflow: hidden;
      .display-element-img {
        width: 150px;
        height: 150px;
        border-radius: 12px;
        overflow: hidden;
      }
      .text {
        width: 95%;
        border-bottom: 0.5px solid #1c1c28;
        margin-top: 8px;
        align-content: center;
        display: flex;
      }
    }
    .new-input {
      margin: auto;
      width: 95%;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.collection-modal .ant-modal-footer {
  display: grid;
  padding: 7px 0px;
  .footer-button {
    align-self: center;
    margin: auto;
    border: 0px;
    font-size: 18px;
    font-weight: 400;
  }
}

.add-collections-popup {
  align-items: center;
  background-color: #1c1c28;
  border-radius: 12px;
  bottom: 0;
  z-index: 100;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 25vw;
  padding: 8px;
  position: fixed;

  .add-popup-text {
    text-align: center;
    width: 65%;
  }

  .add-popup-undo {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 20%;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;