.ant-layout-header {
  width: 100%;
  height: 64px;
  z-index: 10;
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  font-size: 16px;
  filter: drop-shadow(0px 0px 24px rgba(201, 202, 235, 0.5));

  .navLogo {
    display: flex;

    img {
      width: 160px;
    }
  }

  .ant-menu {
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 0px;

    .ant-menu-item {
      height: 100%;
      margin: 0 8px;
      display: flex;
      align-items: center;

      font-size: 16px;
      line-height: normal;
      color: var(--dark-zero);
    }
  }

  .nav-labels {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
  }

  .sessionLabels > .ant-menu-item {
    color: var(--primary-main);
  }

  .nav-profile-button {
    svg path {
      fill: var(--primary-main);
    }
    &.authenticated {
      svg path {
        fill-rule: nonzero;
      }
    }
  }
}

// Responsive
@media (min-width: 768px) and (max-width: 1199px) {
  .ant-layout-header {
    padding: 0 32px;
  }
}

@media (max-width: 767px) {
  .ant-layout-header {
    height: 48px;
    padding: 0 16px;

    .navLogo > img {
      width: 100px;
    }

    .ant-menu {
      .ant-menu-item {
        font-size: 12px;
        margin: 0 4px;
      }
    }
  }

  // Adjust session menu height
  .right-menu .user-profile-side-menu .ant-menu-item {
    height: 48px;
  }
}

// Session Menu
@media (max-width: 1199px) {
  .ant-layout-header {
    .ant-btn-circle {
      border-color: transparent;
    }
  }
  .right-drawer {
    button.ant-btn-icon-only span.anticon svg {
      height: 16px;
    }

    .ant-drawer-body {
      padding: 8px 16px 0 24px;
    }

    .user-profile-side-menu {
      height: 100%;
      padding: 0;

      .ant-menu-item-group-title {
        padding: 12px 0;
      }

      .ant-menu-item {
        height: 48px;
        padding: 0 16px;
      }

      .footer-menu .ant-menu-item {
        padding: 0;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;