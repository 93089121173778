.new-landing-container2 {
  overflow-x: hidden;
  background-color: white;

  .new-landing-content {
    padding-top: 0;
    padding-bottom: 10%;

    .ant-btn-primary {
      width: 100%;

      &:not(:hover) {
        background-color: var(--primary-main);
        border-color: var(--primary-main);
      }
    }

    .new-landing-main-item {
      display: flex;
      margin-left: 7%;
      margin-top: 5%;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      align-items: center;

      .new-button-val {
        grid-column: 1;
        position: relative;
        width: 251px;
        height: 49px;
        margin-top: 5%;
      }
      h1 {
        position: relative;

        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 33px;
        line-height: 46px;

        /* identical to box height, or 141% */

        align-items: center;
        letter-spacing: 0.01em;

        /* Primary / Lighter */
        color: #643eff;
      }

      .logo {
        width: 100%;
        // margin-right: 25%;
        flex-flow: row;
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 4;
        //position: right 0px bottom -250px;
        width: 637px;
        height: 338px;
        border-radius: 12px;
        position: relative;
        bottom: 185px;
      }

      .logo2 {
        width: 100%;
        margin-right: 80%;
        grid-column: 1;
        grid-row-start: 2;
        grid-row-end: 4;
        position: right 0px bottom -250px;
        width: 637px;
        height: 338px;
        border-radius: 12px;
        position: relative;
        bottom: 50px;
      }

      .new-landing-map {
        width: 100%;
        margin-left: 10%;
        grid-column: 1;
        grid-row-start: 2;
        grid-row-end: 4;
        position: right 0px bottom -250px;
        width: 498px;
        height: 207px;
        border-radius: 12px;
        position: relative;
        bottom: -60px;
      }

      .image {
        margin-left: 10%;
      }

      
      p {
        grid-column: 2;
        position: relative;
        width: 526px;
        height: 140px;

        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 33px;
        line-height: 46px;
        /* or 141% */

        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        color: #1c1c28;
      }
      p2 {
        grid-column: 1;
        grid-row-start: 6;
        position: relative;
        width: 337px;
        height: 144px;
        bottom: -50px;
        margin-left: 10%;
        bottom: -90px;

        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;

        /* Dark / Dark 0 */

        color: #1c1c28;
      }
      h2 {
        grid-column: 2;
        position: relative;
        width: 466px;
        height: 93px;

        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 33px;
        line-height: 46px;

        /* identical to box height, or 141% */
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        /* Primary / Lighter */
        color: #643eff;
      }
      text {
        margin-left: 55%;
      }
      p4 {
        color: #4d29dd;
        display: flex;
        width: 482px;
        height: 25px;
        font-size: 19px;
        line-height: 25px;
        align-items: center;
        text-align: center;
        text-decoration-line: underline;
        margin-left: -70%;
      }
      p {
        grid-column: 2;
        position: relative;
        width: 473px;
        height: 101px;

        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 33px;

        /* identical to box height, or 141% */
        display: flex;
        align-items: center;
        line-height: 33.5px;

        /* Primary / Lighter */
        color: #484848;
      }
      .button {
        width: 35%;
        align-items: center;
        margin-left: 30%;
        margin-block-end: 10%;
      }
    }
    .new-landing-box {
      width: 80%;
      display: grid;
      column-gap: 16px;

      background-color: white;
      border-radius: 12px;
      padding: 24px 32px;
      box-shadow: 0px 0px 30px 0px #8187f77a;
      margin-top: 60%;
      position: relative;
      bottom: -50px;

      &.valorization {
        grid-template-columns: 1fr auto 120px;
        margin-left: 7%;
        margin-top: 3%;
      }
      .ant-btn-primary {
        width: 100%;

        &:not(:hover) {
          background-color: var(--primary-main);
          border-color: var(--primary-main);
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .new-landing-container {
    width: 100%;
    //background-image: url('../../assets/Vector36.svg');
    background-repeat: no-repeat;
    background-position: right 0px bottom -550px;
    .new-landing-content {
      padding-top: 0;
      padding-bottom: 10%;

      .ant-btn-primary {
        width: 100%;

        &:not(:hover) {
          background-color: var(--primary-main);
          border-color: var(--primary-main);
        }
      }

      .new-landing-main {
        .new-landing-main-item {
          margin-left: 10%;
          margin-top: 10%;
          display: grid;
          grid-template-columns: auto;
          grid-template-rows: auto 1fr auto auto;
          align-items: center;

          .new-button-val {
            grid-column: 1;
            position: relative;
            width: 251px;
            height: 49px;
            margin-top: 5%;
          }

          .new-landing-map {
            grid-column: 1;
            grid-row-start: 3;
            grid-row-end: 3;
            position: relative;
            bottom: 30px;
            left: -5%;
            width: 70%;
          }

          h1 {
            grid-column: 1;
            position: relative;
            width: 418px;
            height: 47px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 46px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: #643eff;
          }
          p {
            grid-column: 1;
            position: relative;
            width: 619px;
            height: 80px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
            /* or 141% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* Dark / Dark 0 */

            color: #1c1c28;
          }
          p2 {
            grid-column: 1;
            position: relative;
            width: 617px;
            height: 92px;
            b

            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            /* or 167% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* Dark / Dark 0 */

            color: #1c1c28;
          }
          li {
            list-style-type: square;
          }
          p3 {
            grid-column: 2;
            position: relative;
            width: 473px;
            height: 213px;

            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            /* or 167% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* Dark / Dark 0 */

            color: #484848;
          }
        }
        .new-landing-box {
          width: 80%;
          display: grid;
          column-gap: 16px;

          background-color: white;
          border-radius: 12px;
          padding: 24px 32px;
          box-shadow: 0px 0px 20px 0px #8186f7;

          &.valorization {
            grid-template-columns: 1fr auto 120px;
            margin-left: 7%;
            margin-top: 3%;
          }
          .ant-btn-primary {
            width: 100%;

            &:not(:hover) {
              background-color: var(--primary-main);
              border-color: var(--primary-main);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .new-landing-container {
    width: 100%;
    //background-image: url('../../assets/Vector36.svg');
    background-repeat: no-repeat;
    background-position: right 0px bottom -550px;
    .new-landing-content {
      width: unset;
      padding-top: 0;
      padding-bottom: 10%;

      .ant-btn-primary {
        width: 100%;

        &:not(:hover) {
          background-color: var(--primary-main);
          border-color: var(--primary-main);
        }
      }

      .new-landing-main {
        height: unset;
        .new-landing-main-item {
          margin-left: 10%;
          margin-top: 10%;
          width: unset;
          display: block;
          align-items: unset;

          .new-button-val {
            grid-column: 1;
            position: relative;
            margin-top: 10%;
          }

          .new-landing-map {
            width: unset;
            position: relative;
            bottom: unset;
            left: unset;
            height: unset;

            svg {
              width: fit-content;
              height: min-content;
            }
          }

          h1 {
            width: unset;
            grid-column: 1;
            position: relative;
            width: unset;
            height: unset;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 46px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: #643eff;
          }
          p {
            width: unset;
            grid-column: 1;
            position: relative;
            width: unset;
            height: unset;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            /* or 141% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* Dark / Dark 0 */

            color: #1c1c28;
          }
          p2 {
            width: unset;
            grid-column: 1;
            position: relative;
            height: unset;

            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            /* or 167% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* Dark / Dark 0 */

            color: #1c1c28;
          }
        }
        .new-landing-box {
          width: 80%;
          display: grid;
          column-gap: 16px;

          background-color: white;
          border-radius: 12px;
          padding: 24px 32px;
          box-shadow: 0px 0px 20px 0px #8186f7;
          margin-bottom: 70px;

          &.valorization {
            grid-template-columns: 1fr auto 120px;
            margin-left: 7%;
            margin-top: 3%;
          }
          .ant-btn-primary {
            width: 100%;

            &:not(:hover) {
              background-color: var(--primary-main);
              border-color: var(--primary-main);
            }
          }
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;