.appraisals-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;

  .anticon path {
    fill: var(--dark-one);
  }

  th {
    font-size: 14px;
    color: var(--dark-one);
    font-weight: normal;
    text-align: center;
    padding: 0 8px;

    em {
      font-style: normal;
      font-weight: bold;
    }

    &.created-at-header,
    &.actions-header {
      width: 128px;
    }
  }

  tbody tr {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4),
      0px 2px 4px rgba(201, 202, 235, 0.4);
  }

  &.massive tbody tr {
    height: 96px;
  }

  td {
    font-size: 13px;
    padding: 0 16px;
    text-align: center;

    img {
      width: 100%;
      height: 96px;
      border-bottom-left-radius: 12px;
      border-top-left-radius: 12px;
      object-fit: cover;
    }

    &.no-padding {
      padding: 0;
    }

    &.text-align-left {
      text-align: left;
    }

    // Massive
    &.checkbox-cell {
      padding-right: 64px;
      span {
        border-spacing: 0;
        border-radius: 4px;
      }
    }

    &.actions-cell {
      .ant-btn {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.btn-see-more {
  display: block;
  margin: 16px auto 32px;
}

@media (max-width: 1023px) {
  .appraisals-table {
    th {
      font-size: 12px;

      &.location-header {
        width: 120px;
      }
    }

    tbody tr {
      border-radius: 0;
    }

    td {
      font-size: 12px;
      padding: 0 8px;

      img {
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      &.checkbox-cell {
        padding: 0 8px;
      }
    }
  }
}

@media (max-width: 767px) {
  .appraisals-table {
    border-spacing: 0 16px;

    .type-header,
    .location-header,
    .created-at-header,
    .type-cell,
    .location-cell,
    .created-at-cell {
      display: none;
    }

    th {
      font-size: 9px;

      &.actions-header {
        min-width: 80px;
      }
    }

    tbody tr,
    &.massive tbody tr {
      border-radius: 0;
      height: 64px;
    }

    td {
      font-size: 11px;
      padding: 0 4px;

      img {
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      .anticon {
        font-size: 12px;
      }

      &.text-align-left {
        padding-left: 16px;
      }

      &.property-count {
        width: 104px;
      }

      &.actions-cell {
        min-width: 80px;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;