//
// Layout
//
.cardContainer {
  display: flex;
  height: 320px;

  .ant-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;

    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  }

  &.horizontal {
    height: 144px;
    flex: 1 1 400px;

    .ant-card {
      flex-direction: row;
    }
  }

  &.hiden {
    .ant-card {
      background: #adadb1;
    }
    .ant-card::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(209, 209, 209, 0.6);
    }
  }
}

//
// Card
//
.cardCoverFit,
.cardCover {
  width: 100%;
  height: 144px;
  .cardCoverIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
  }
  .cardCoverImageSelector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
}

.arrowSelector {
  display: inline-block;
}

.cardCoverImageSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vh;
  width: 100%;
}

.cardCoverIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
}

.cardCover {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.coverIcon {
  display: inline-block;
}

.cardCoverFit {
  background-size: cover;
}

.ant-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 8px 16px;

  &::before,
  &::after {
    display: none;
  }
}

.cardPublicationData {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.cardPropertyType {
    font-size: 12px;
    font-weight: 300;
  }
  span.cardPropertyDate {
    font-size: 10px;
    font-weight: 300;
  }
}

span.cardPrice {
  color: var(--dark-zero);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-right: 8px;
}

.cardEstimatedPriceSection {
  color: var(--primary-main);
  margin-top: -6px;
  font-weight: 400;

  span.cardEstimatedPrice {
    font-size: 14px;
  }
  img {
    width: 13px;
    margin: 0 4px;
  }
  span.cardEstimatedPriceLabel {
    font-size: 10px;
    text-decoration: underline;
  }
}

.cardDescription {
  margin-bottom: 8px;
  color: var(--dark-zero);
  font-size: 12px;

  // Fit 3 lines
  @description-line-height: 18px;
  line-height: @description-line-height;
  height: @description-line-height * 3;

  // No scrollbar
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 24px;

  .cardFooterData {
    display: flex;
    align-items: center;

    font-size: 10px;
    font-weight: 300;
    color: var(--dark-zero);

    svg {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
}

//
// Skeleton
//
.skeletonCard {
  .cardCoverFit {
    color: transparent;
    background: linear-gradient(
      100deg,
      var(--primary-subtle) 30%,
      var(--primary-lavander) 50%,
      var(--primary-subtle) 70%
    );
    animation: loading 1.4s ease-in-out infinite;
    background-size: 290%;

    @keyframes loading {
      0% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  .ant-skeleton-content {
    .ant-skeleton-title {
      margin: 0 0 16px;
      height: 24px;
    }

    .ant-skeleton-paragraph {
      margin: 4px 0;
      & > li {
        height: 12px;
        margin-top: 4px !important;
      }
    }
  }

  .cardFooterData {
    height: 24px;
    width: 32px;
  }

  .cardFooterData.dataArea {
    width: 88px;
  }
}

//
// Horizontal Card
//
.horizontal .ant-card {
  .cardCover,
  .cardCoverFit {
    width: 200px;
  }

  .ant-card-body {
    max-width: 296px;
    margin: 0 auto;
  }

  .cardFooter {
    justify-content: space-between;
  }
}

//
// Simple Card
//
.ant-card.simple {
  height: 256px;
}

//hiden card

//
// Responsive for small devices
//
@media (max-width: 600px) {
  // Card
  .cardContainer {
    height: 256px;
  }

  .cardCoverFit,
  .cardCover {
    height: 104px;
  }

  .ant-card-body {
    padding: 4px 8px;
  }

  .cardPublicationData {
    span.cardPropertyType {
      font-size: 8px;
    }
    span.cardPropertyDate {
      font-size: 8px;
    }
  }

  .cardPriceSection {
    span.cardPrice {
      font-size: 14px;
    }
    span.cardEstimatedPrice {
      font-size: 12px;
    }
    span.cardEstimatedPriceLabel {
      font-size: 9px;
    }
  }

  p.cardDescription {
    font-size: 10px;
  }

  .cardFooter {
    .cardFooterData {
      svg {
        height: 12px;
        width: 12px;
        margin-right: 4px;
      }
    }
  }

  // Skeleton
  .skeletonCard {
    .ant-skeleton-content {
      .ant-skeleton-title {
        margin: 0 0 12px;
        height: 20px;
      }

      .ant-skeleton-paragraph {
        margin: 4px 0;
        & > li {
          height: 10px;
          margin-top: 4px !important;
        }
      }
    }
    .cardFooterData {
      height: 18px;
      width: 24px;
    }

    .cardFooterData.dataArea {
      width: 64px;
    }
  }
}

//
// Data cards
//

.data-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  flex-basis: 144px;
  min-height: 128px;
  max-height: 160px;

  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 26.1294px 20.9036px rgba(108, 73, 172, 0.035),
    0px 13.8771px 11.1017px rgba(108, 73, 172, 0.0282725),
    0px 5.77458px 4.61966px rgba(108, 73, 172, 0.0196802);

  &.outlined {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--primary-lavander);
  }

  * {
    margin: 0;
  }

  .anticon {
    position: absolute;
    left: 12px;
    top: 12px;
    font-size: 32px;
    svg path {
      fill: var(--primary-main);
    }
  }
  #hola {
    fill: #4d29dd00;
  }
  #fill_user {
    fill: #00000200;
  }

  h3,
  .data-card-body,
  .data-card-footer {
    text-align: center;
  }

  h3 {
    font-size: 18px;
    font-weight: normal;
    padding: 0 40px;
  }
  .data-card-body {
    font-size: 24px;
    font-weight: 600;
  }
  .data-card-footer {
    font-size: 16px;
    color: var(--dark-two);
  }

  &.large {
    max-height: 200px;
    .anticon {
      left: 16px;
      top: 16px;
      font-size: 40px;
    }
    h3 {
      padding: 0 48px;
      font-size: 24px;
    }
    .data-card-body {
      font-size: 36px;
    }
    .data-card-footer {
      font-size: 18px;
    }
  }

  &.small {
    min-height: 72px;
    padding: 8px;

    .anticon {
      position: absolute;
      left: 8px;
      top: 8px;
      font-size: 20px;
    }

    h3 {
      padding: 0 24px;
      font-size: 10px;
    }

    .data-card-body {
      font-size: 14px;
      margin: 4px 0;
    }

    .data-card-footer {
      font-size: 9px;
    }
  }
}

//
// Feature Card
//

.feature-card {
  min-width: 160px;
  max-width: 320px;
  height: 360px;
  display: flex;
  flex-direction: column;

  padding: 16px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(201, 202, 235, 0.4), 0px 8px 16px rgba(201, 202, 235, 0.4);

  img {
    width: 100%;
  }
  h4 {
    margin: auto;
    font-size: 24px;
    font-weight: 400;
  }
  p {
    margin: auto;
    font-size: 18px;
  }
}

// Property card

.card-main-container {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
  border-radius: 12px;
  display: flex;
  flex: 1;
  max-height: 200px;
  flex-direction: row;
  height: calc(8vw + 6.5vh + 2vmin);
  margin: 0.5vh;
}

.row-flex-div {
  margin-bottom: 5px;
  align-content: left;
}

.card-column-flex-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 5px 5px 5px;
  margin: 0px 0px 0px 0px;
}

.card-column-flex-div-first {
  display: flex;
  flex-direction: column;
  width: 120%;
  padding: 5px;
  margin: 0px 16px 0px 0px;
}

.property-card-image {
  box-sizing: border-box;
  display: flex;
  margin-right: 0;
  max-width: 20%;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 30%;
}

.card-primary-text {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
}

.card-secondary-text {
  margin-top: 2vh;
  font-weight: 300;
  font-size: 13px;
}

.card-secondary-text-purple {
  margin-top: 2vh;
  font-weight: 300;
  font-size: 13px;
  color: #4124b5;
}

.card-description-container {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: auto;
  padding: 10px;
  width: 70%;
}

.card-divider {
  height: 96%;
  border-color: #c9caeb;
}

@media (max-width: 1199px) {
  .card-main-container {
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
    border-radius: 0px;
    display: flex;
    flex: 1;
    max-height: 18vh;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0.5vh;
  }
  .property-card-image {
    box-sizing: border-box;
    display: flex;
    margin-left: 0;
    max-width: 20%;
    overflow: hidden;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 30%;
  }
}

@media (max-width: 767px) {
  .feature-card {
    height: 240px;

    h4 {
      font-size: 14px;
    }
    p {
      font-size: 11px;
    }
  }
  .card-primary-text {
    font-size: 10px;
  }
  .card-secondary-text {
    font-size: 9px;
    margin-top: 0.5vh;
  }
  .card-secondary-text-purple {
    font-size: 9px;
    margin-top: 0.5vh;
  }
  .row-flex-div {
    margin-top: 6px;
    align-content: left;
  }
  .card-main-container {
    max-height: 15vh;
  }
  .card-column-flex-div {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0px 7px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .card-column-flex-div-first {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0px 7px 0px 0px;
    margin: 0px 0px 0px 0px;
  }
}

.blocked {
  color: blueviolet;
  font-weight: 400;
  font-size: 14px;
  justify-content: space-evenly;
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;