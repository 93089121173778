@font-face {
  font-family: "Mulish";
  src: url("./Mulish-Regular.ttf");
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-ExtraLightItalic.ttf");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Mulish";
  src: url("./Mulish-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-ExtraLightItalic.ttf");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-SemiBoldItalic.ttf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-ExtraBoldItalic.ttf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-MediumItalic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;