@import "~antd/dist/antd.less";
@import "../src/assets/fonts/index.css";

:root {
  height: 100%;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: var(--primary-subtle);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primary-lavander);
  }
  scrollbar-width: thin;
  scrollbar-color: var(--primary-lavander) var(--primary-subtle);

  @media (hover: none) {
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
}

body {
  --primary-darker: #4124b5;
  --primary-main: #4d29dd;
  --primary-lighter: #643eff;
  --primary-subtle: #e1e1f2;
  --primary-lavander: #c9caeb;
  --primary-slim: #8186f7;
  --primary-background: #f0f0ff;
  --dark-zero: #1c1c28;
  --dark-one: #484848;
  --dark-two: #686a88;
  --dark-three: #8f90a6;
  --dark-four: #b3b7cd;
  --red-zero: #dc5151;
  --green-zero: #00b667;
  --light-one: #ebebf0;
  --light-four: #ffffff;

  height: 100%;
  font-family: Poppins;
  font-size: 16px;
}

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;

  .ant-layout-content {
    overflow: auto;
  }
}

.dummy {
  display: none !important;
}

// Antd Components
.ant-checkbox {
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 1px 2px rgba(0, 0, 0, 0.32);

  .ant-checkbox-inner {
    border-radius: 4px;
  }
}

.ant-image-preview-img {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}

.ant-modal-content {
  box-shadow: 0px 0px 20px 0px #8186f7;
  border-radius: 12px;
  background-color: white;
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;