.userManagementTable {
  color: var(--dark-one);
  .userManagementFilterHeaders {
    height: 80px;
    background-color: var(--primary-background);
    padding: 0px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .userRoleSelect {
    width: 50%;
    display: flex;
    justify-content: space-around;
  }
  .userManagementTableColumn {
    img:hover {
      cursor: pointer;
    }
  }
  .ant-table {
    .ant-select-selector {
      color: var(--dark-one);
      padding-left: 0px !important;
    }
    .ant-switch-checked {
      background-color: var(--primary-slim);
    }
    table {
      color: var(--dark-one);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
    thead {
      th {
        height: 80px;
      }
    }
    th {
      color: var(--dark-one);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .disabledRow {
    color: var(--primary-lavander);
    .ant-select-selector {
      color: var(--primary-lavander);
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;