.modal-auth .ant-modal-body,
.drawer-auth .ant-drawer-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .google-auth-divider {
    margin: 4px;
    color: var(--dark-three);
  }

  .footer-link {
    margin-top: 16px;
    span {
      text-decoration: underline;
    }
  }
}

.drawer-auth {
  z-index: 9;
}

.auth-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--primary-main);
    font-size: 32px;
    text-align: center;
    margin: auto;
    line-height: normal;
    margin-bottom: 32px;
  }

  .ant-steps {
    margin-bottom: 32px;
    width: 400px;
  }

  .auth-form {
    width: 100%;
    display: grid;
    justify-content: stretch;
    justify-items: center;
    gap: 8px;

    .ant-form-item {
      width: 100%;
    }
  }

  .auth-forgot-container {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
  }
}

.adminRegisterModal {
  padding: 20px 40px;
  .ant-row {
    margin: 0;
  }
  .ant-col {
    height: 100px;
  }
  .ant-btn {
    width: 30%;
  }
  h1 {
    color: var(--primary-main);
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    padding-bottom: 30px;
  }
  .dualRow {
    display: flex;
    justify-content: space-between;
    .ant-row {
      flex-basis: 47%;
    }
  }
  .columnRow {
    display: flex;
    flex-direction: column;
    width: 33.5%;
    align-items: flex-end;

    .ant-row {
      width: 90%;
    }
    .ant-select {
      width: 100%;
    }
  }
  .formRowThree {
    display: flex;
    justify-content: space-between;
    .ant-row {
      width: 48%;
    }
  }
  .formSubmitButton {
    display: flex;
    justify-content: center;
  }
}

.adminRegisterModal {
  padding: 20px 40px;
  .ant-row {
    margin: 0;
  }
  .ant-col {
    height: 100px;
  }
  .ant-btn {
    width: 30%;
  }
  h1 {
    color: var(--primary-main);
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    padding-bottom: 30px;
  }
  .dualRow {
    display: flex;
    justify-content: space-between;
    .ant-row {
      flex-basis: 47%;
    }
  }
  .columnRow {
    display: flex;
    flex-direction: column;
    width: 33.5%;
    align-items: flex-end;

    .ant-row {
      width: 90%;
    }
    .ant-select {
      width: 100%;
    }
  }
  .formRowThree {
    display: flex;
    justify-content: space-between;
    .ant-row {
      width: 48%;
    }
  }
  .formSubmitButton {
    display: flex;
    justify-content: center;
  }
}

// Move label on focus or when input is not empty
input:focus,
input:not(:placeholder-shown) {
  & ~ .label-float {
    top: 0%;
    opacity: 1;
    color: var(--primary-main);
    background: white;
  }

  span & {
    & ~ .label-float {
      top: 0%;
      opacity: 1;
      color: var(--primary-main);
      background: white;
    }
  }
}

.label-float {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  pointer-events: none;
  transition: 0.2s ease all;
  z-index: 1;
  color: var(--dark-three);
  line-height: 100%;
}

@media (max-width: 767px) {
  .auth-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      margin: 0 0 32px;
    }

    .ant-steps {
      width: 100%;
    }

    .auth-form {
      gap: 0;

      div.ant-form-item-explain {
        margin-left: 4px;
        font-size: 10px;
      }
    }
  }

  .modal-auth .ant-modal-body,
  .drawer-auth .ant-drawer-body {
    .google-auth-divider {
      margin: 0;
    }

    .footer-link {
      margin-top: 8px;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;