.appraisal-modal {
  top: 80px;
  max-height: 800px;
  min-height: 640px;

  .ant-modal-content {
    height: 100%;
    overflow: hidden;

    .ant-modal-body {
      height: 100%;
      padding: 0;
    }
  }
}

.appraisal-drawer {
  z-index: 9;
  .ant-drawer-body {
    padding: 0;

    .btn-go-back {
      position: absolute;
      font-size: 10px;
      top: 4px;
      width: auto;

      span:not(.anticon) {
        margin-left: 4px;
      }
    }
  }
}

.payment-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;

  .ant-btn-primary {
    //width: 130%;

    &:not(:hover) {
      background-color: var(--primary-main);
      border-color: var(--primary-main);
    }
  }

  .payment-form {
    display: flex;
    flex-direction: column;
    padding: 50px;

    img {
      //margin: 7px auto;
      text-align: left;
      margin-right: 40%;
    }
    .image {
      background-size: 30px 30px;
      width: 450 px;
    }

    p2 {
      width: 337px;
      height: 144px;
      position: relative;
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      color: #1c1c28;
      margin-left: 17%;
    }
  }
  .second-container {
    //display: flex;
    // flex-direction: column;
    padding: 50px;
    margin-top: 14px;
    padding: 24px;
    // padding-left: 0;
    padding-right: 24px;

    h2 {
      grid-column: 2;
      position: relative;
      width: 466px;
      height: 93px;

      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 33px;
      line-height: 46px;

      /* identical to box height, or 141% */
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;

      /* Primary / Lighter */
      color: #643eff;
    }
    p {
      position: relative;
      width: 473px;
      height: 101px;

      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 33px;
      /* or 141% */
      display: flex;
      align-items: center;

      color: #1c1c28;
    }
    .pru {
      width: 400px;
      height: 210px;
      margin-left: 3%;
      p3 {
        grid-column: 2;
        position: relative;
        width: 383px;
        height: 213px;

        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #484848;
        li {
          list-style-type: square;
          line-height: 1.5;
        }
      }
    }

    .button {
      width: 35%;
      align-items: center;
      margin-left: 30%;
      margin-block-end: 10%;
      .cont2 {
        width: 150px;
        height: 25px;
        p4 {
          color: #4d29dd;
          display: flex;
          width: 482px;
          height: 25px;
          font-size: 17px;
          line-height: 25px;
          //align-items: center;
          //text-align: center;
          text-decoration-line: underline;
          margin-left: -80%;
        }
      }
    }
  }
}

@media (max-width: 1230px) {
  .payment-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    .ant-btn-primary {
      //width: 130%;

      &:not(:hover) {
        background-color: var(--primary-main);
        border-color: var(--primary-main);
      }
    }
    .second-container {
      padding: 60px;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-left: 10%;
      h2 {
      }
      p {
      }
      .pru {
        p3 {
          li {
            list-style-type: square;
            line-height: 1.5;
          }
        }
      }

      .button {
        .cont2 {
          p4 {
          }
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;