.indicatorsView {
  align-content: start;
  height: 100%;
  overflow: hidden;

  .site-layout-background {
    display: contents;
    .bar-and-filters {
      display: flex;
      margin: 20px;
      justify-content: space-between;

      .select-direction-bar-container {
        margin: 0 4px;
      }
      .filterToggle {
        flex: content;
        margin: 0 4px;
        max-width: max-content;
        min-width: max-content;
      }
      .filterPopover {
        flex: content;
        margin: 0 4px;
      }
    }

    .map-container {
      position: relative;
      overflow: hidden;
      height: 100%;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 6px 32px 6px;
  margin-right: 0px;

  background-color: var(--primary-background);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  vertical-align: middle;
}

.tools{
  height: auto;
  padding-top:20px;
  align-items:  center;
}

.description{
  height: 100px;
}

.selected-zone{
  align-self: center;
  padding-left: 25px;
  width: 100%;
  font-weight: 500px;
  font-size: 18px;
}

.custom{
  background-color: #F8F8FF;
  font-size: 15px;
  font-weight: 400;
  height: 35px;
}

.indicators{
  padding-top: 10px;
}
@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;